<template>
  <section>
    <div class="content-header">
      <h2>“Unhiding” a hidden curriculum</h2>
    </div>
    <div class="content-wrapper">
      <p>The first step in dealing with a hidden curriculum is to recognize it and understand how it can influence your students’ resilience. Ask yourself these three questions:</p>
      <ul>
        <li>What are your <strong class="bold">prevailing opinions</strong>, and how do they make their way into learning activities?</li>
        <li>How do you <strong class="bold">manage your classes</strong>, and how might those negatively impact some students?</li>
        <li>Do you <strong class="bold">check in with your most vulnerable students</strong> individually to ensure they understand what’s expected of them?</li>
      </ul>
      <p class="pt-3">Take a few moments to think about some of your own assumptions and expectations for classroom behaviours. If they are not documented or shared with all of your students in the same way, some of your students may be at a disadvantage. </p>
      <p class="lm ">Learn more</p>
      <p>This resource helps to <a
          href="https://openstax.org/books/college-success/pages/2-7-the-hidden-curriculum"
          target="_blank"
        >define the hidden curriculum</a>, particularly from students’ perspectives. and how it can impact the learning experience. </p>
      <p>Before you craft a course outline or plan a lesson, review this resource and consider <a
          href="https://www.bu.edu/teaching-writing/resources/teaching-the-hidden-curriculum/"
          target="_blank"
        >how the hidden curriculum gets reproduced in your classroom</a>. </p>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
